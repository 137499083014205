import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useState, useEffect } from 'react';
import useTAC from '../Content/useTAC';

const AddTAC = () => {
  const [content, setContent] = useState('');
  const { TAC, load, err, fetchTAC } = useTAC();

  useEffect(() => {
    if (TAC) {
      setContent(TAC);
    }
  }, [TAC]); // Depend on fetchaboutus if it's a function

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    // Handle form submission
    try {
      const response = await fetch(`https://backend.amjgems.com/public/api/admin/tandc/${user_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Using Bearer token authentication
        },
        body: JSON.stringify({ user_id: parseInt(user_id), terms_and_conditions: content }),
      });
      const result = await response.json();
      alert("Added Successful");
      form.reset();
    } catch (error) {
      console.error('Error adding product:', error);
    }
    console.log('About Us Content:', content);
  };

  return (
    <div id='adminformdivau'>
      <form id='TACform' onSubmit={handleSubmit}>
        <label>Terms and Conditions : </label>
        <div className='ckeditor-container'>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
          />
        </div>
        <button className='adminbutton' type='submit'>Add Terms and Conditions</button>
      </form>
    </div>
  );
}

export default AddTAC