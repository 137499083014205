import React from 'react'
import './midcontent.scss'
import useProducts from '../Admin/useProducts';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Filtercontroller from './Filtercontroller';
import { Link } from 'react-router-dom';
const Home = () => {

  const { category_code } = useParams();

  const [filters, setFilters] = useState({
    category_code: '',
    min_price: null,
    max_price: null,
    per_page: 10,
  });

  const { products, load, err, fetchproducts } = useProducts(filters);

  useEffect(() => {
    // Log the category_code to debug
    setFilters(prevFilters => ({
      ...prevFilters,
      category_code: category_code || '', // Default to '' if category_code is null
    }));
    console.log(filters);
  }, [category_code]);

  useEffect(() => {
    console.log(filters);
    fetchproducts();
  }, [filters.category_code]);

  console.log(products);

  return (
    <>
      <Filtercontroller />
      <section id='midcontent'>
        {products.map((product) => (
          <Link className='link' to={`/productshow/${product.product_code}`}>
            <div key={product.id} class="card">
              <div className='image-container'>
                <img src={`https://backend.amjgems.com/public/${product.product_img_main}`} alt="Captivating Elegance Diamond Earrings" />
              </div>
              <h3>{product.product_name}</h3>
              <p class="price">₹{product.product_price * (1 - product.discount_percent / 100)}<span class="price-cut">₹{product.product_price}</span></p>
              {product.product_quantity === 0 ?
                (<p class="discount">Out of stock!</p>):
                (<p class="discount">Only {product.product_quantity} left!</p>)
              }
            </div>
          </Link>
        ))}
      </section>
    </>

  )
}

export default Home