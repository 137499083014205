import React, { useState } from 'react';
import useCategories from './useCategories';
import useProducts from './useProducts';
import { useEffect } from 'react';
import UpdateProductModal from './UpdateProductModal';

const AddProductForm = () => {

  const [productcode, setProductcode] = useState();
  const [updateShow, setupdateShow] = useState(false);

  const handleClose = () => {
    setupdateShow(false);
  };
  const handleShowupdate = (e) => {
    setProductcode(e.target.id);
    setupdateShow(true);
  }

  const [filters, setFilters] = useState({
    category_code: '',
    min_price: null,
    max_price: null,
    per_page: 10,
  });
  const { categories, loading, error, fetchCategories } = useCategories();
  const { products, load, err, fetchproducts } = useProducts(filters);
  const [formData, setFormData] = useState({
    productName: '',
    productCategory: '',
    productQuantity: '',
    productDesc: '',
    productPrice: '',
    productMainImage: null,
    productImage1: null,
    productImage2: null,
    productImage3: null,
    product_delivery_time: null,
    discount_percent: null,
    discount_time: null,
    user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
    product_carat: null,
    product_weight: null
  });

  useEffect(() => {
    if(updateShow == false)
    {
      fetchproducts();
    }
  }, [updateShow]);


  const deleteproduct = async (event) => {
    const id = event.target.id;
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    try {
      await fetch(`https://backend.amjgems.com/public/api/products/${id}`, { // Replace with your API endpoint
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Using Bearer token authentication
        },
        body: JSON.stringify({ user_id: user_id }),
      });
      fetchproducts(); // Refresh categories
    } catch (error) {
      console.error('Error adding category:', error);
    }
  }

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;

    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Handle only the first file
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const token = localStorage.getItem('token');
    // Create a new FormData object
    const data = new FormData();
    data.append('product_name', formData.productName || '');
    data.append('product_category', formData.productCategory || '');
    data.append('product_quantity', formData.productQuantity || '');
    data.append('product_desc', formData.productDesc || '');
    data.append('product_price', formData.productPrice || '');

    // Append image files only if they exist
    data.append('product_img_main', formData.productMainImage || '');
    data.append('product_img_1', formData.productImage1 || '');
    data.append('product_img_2', formData.productImage2 || '');
    data.append('product_img_3', formData.productImage3 || '');

    // Append delivery time or empty string if not provided
    data.append('product_delivery_time', formData.product_delivery_time || '');
    data.append('discount_percent', formData.discount_percent || '');
    data.append('discount_time', formData.discount_time || '');
    data.append('product_carat', formData.product_carat || '');
    data.append('product_weight', formData.product_weight || '');
    data.append('user_id', formData.user_id || '');
    console.log(formData);

    try {
      const response = await fetch('https://backend.amjgems.com/public/api/products', {
        method: 'POST',
        body: data, // FormData automatically sets the correct Content-Type header
        headers: {
          'Authorization': `Bearer ${token}` // Using Bearer token authentication
        },
      });
      // console.log(response);
      const result = await response.json();
      console.log('Product added successfully:', result);
      // Handle success (e.g., show a message or reset the form)
      fetchproducts();
      form.reset();
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <div>
      <div id='adminformdiv'>
        <form id='addproductsform' onSubmit={handleSubmit}>
          <label>Enter Artical Name</label>
          <input
            className='admininput'
            type="text"
            name='productName'
            placeholder='Product Name'
            required
            value={formData.productName}
            onChange={handleChange}
          />
          <label>Choose a Category:</label>
          <select
            className='admininput'
            id="productCategory"
            name="productCategory"
            required
            value={formData.productCategory}
            onChange={handleChange}
          >
            <option value="">Select a Category</option>
            {categories.map((category) => (
              <option key={category.category_code} value={category.category_code}>{category.category_code}</option>
            ))}
          </select>
          <label>Enter Artical Quantity</label>
          <input
            className='admininput'
            type="text"
            name='productQuantity'
            placeholder='Product Quantity'
            required
            value={formData.productQuantity}
            onChange={handleChange}
          />
          <label>Enter Artical Discription</label>
          <input
            className='admininput'
            type="text"
            name='productDesc'
            placeholder='Product Description'
            required
            value={formData.productDesc}
            onChange={handleChange}
          />
          <label>Enter Artical Price</label>
          <input
            className='admininput'
            type="number"
            name='productPrice'
            placeholder='Product Price'
            required
            value={formData.productPrice}
            onChange={handleChange}
          />
          <label>Enter Artical Karat</label>
          <input
            className='admininput'
            name='product_carat'
            type="text"
            placeholder='Enter Artical Karat'
            required
            onChange={handleChange}
          />
          <label>Enter Weight of Artical</label>
          <input
            className='admininput'
            name='product_weight'
            type="text"
            placeholder='Enter Weight of Artical'
            required
            onChange={handleChange}
          />
          <label>Upload Main Image</label>
          <input
            className='admininput'
            name='productMainImage'
            type="file"
            required
            onChange={handleChange}
          />
          <label>Upload Image 1</label>
          <input
            className='admininput'
            name='productImage1'
            type="file"
            onChange={handleChange}
          />
          <label>Upload Image 2</label>
          <input
            className='admininput'
            name='productImage2'
            type="file"
            onChange={handleChange}
          />
          <label>Upload Image 3</label>
          <input
            className='admininput'
            name='productImage3'
            type="file"
            onChange={handleChange}
          />
          <label>% Discount Precentage :</label>
          <input
            className='admininput'
            name='discount_percent'
            type="number"
            placeholder='Discount Percent'
            onChange={handleChange}
          />
          <label>Last Date to Receive Discount (date should be later than today) :</label>
          <input
            className='admininput'
            name='discount_time'
            type="date"
            placeholder='Discount Last Date'
            onChange={handleChange}
          />
          <label>Product Dilavery Time :</label>
          <input
            className='admininput'
            name='product_delivery_time'
            type="number"
            placeholder='Product Delivery Time'
            required
            onChange={handleChange}
          />
          <button className='adminbutton' type='submit'>Add Product</button>
        </form>
      </div>
      <div>
        <table className='table table-responsive table-bordered'>
          <thead>
            <tr id='tableheading'>
              <th>Product_id</th>
              <th>Product_code</th>
              <th>Product_name</th>
              <th>Category_id</th>
              <th>Product_Quantity</th>
              <th>Product_Disc</th>
              <th>Product_Price</th>
              <th>Discount_Percent</th>
              <th>Discount_Time</th>
              <th>Product_Carat</th>
              <th>Product_Weight</th>
              <th>Product_img_M</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr id='tablerow' key={product.product_code}>
                <td>{product.id}</td>
                <td>{product.product_code}</td>
                <td>{product.product_name}</td>
                <td>{product.product_category}</td>
                <td>{product.product_quantity}</td>
                <td>{product.product_desc}</td>
                <td>$ {product.product_price}</td>
                <td>{product.discount_percent} %</td>
                <td>{product.discount_time}</td>
                <td>{product.product_carat}</td>
                <td>{product.product_weight}</td>
                <td><img className='img-thumbnail' src={`https://backend.amjgems.com/public/${product.product_img_main}`} alt="" /></td>
                <td><img className='img-thumbnail' src={`https://backend.amjgems.com/public/${product.product_img_1}`} alt="" /></td>
                <td><img className='img-thumbnail' src={`https://backend.amjgems.com/public/${product.product_img_2}`} alt="" /></td>
                <td><img className='img-thumbnail' src={`https://backend.amjgems.com/public/${product.product_img_3}`} alt="" /></td>
                <td><button className='adminbutton' id={product.product_code} onClick={deleteproduct}>delete</button></td>
                <td><button className='adminbutton' onClick={handleShowupdate} id={product.product_code}>update</button></td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <UpdateProductModal show={updateShow} onClose={handleClose} product_code={productcode} />
    </div>

  );
};

export default AddProductForm;
