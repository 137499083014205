import React from 'react'
import { Outlet } from 'react-router-dom';
import { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Check = () => {
    const navigate = useNavigate();
    const [isAuthorized, setIsAuthorized] = useState(null); // null means loading

    const check = async () => {
      const token = localStorage.getItem('token');
  
      try {
        const response = await fetch('https://backend.amjgems.com/public/api/user-check', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Using Bearer token authentication
          },
          body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
        });
        const data = await response.json();
        console.log(data);
        setIsAuthorized(data.success);
  
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAuthorized(false); // Optionally set to false on error
      }
    };
  
    useEffect(() => {
      check();
    });
  
    if (isAuthorized === null) {
      // Render nothing or a loading indicator while the check is in progress
      return <div>Loading...</div>;
    }
  
    if (isAuthorized) {
      return <Outlet />; // Render the child routes if authorized
    } else {
      return navigate('/'); // Render the login component if not authorized
    }
}

export default Check