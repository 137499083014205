// useproducts.js
import { useState } from 'react';

const useProducts = (filters={}) => {
  const [products, setproducts] = useState([]);
  const [load, setLoad] = useState(true);
  const [err, setErr] = useState(null);

  const fetchproducts = async () => {
    try {
      const response = await fetch('https://backend.amjgems.com/public/api/products/fetch' , {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filters),
      }); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setproducts(data);
      setErr(null);
    } catch (error) {
      setErr(error.message);
    } finally {
      setLoad(false);
    }
  };

  // useEffect(() => {
  //   fetchproducts();
  // }, []);

  return { products, load, err, fetchproducts };
};

export default useProducts;