import React from 'react'
import { useState } from 'react';

const useSingleCategory = (category_code) => {
    const [singlecategory, setsinglecategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchSingleCategory = async () => {
        const queryParams = new URLSearchParams({ category_code: category_code }).toString();
        try {
            const response = await fetch(`https://backend.amjgems.com/public/api/categories/${category_code}`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            setsinglecategory(data.category);
            setError(null);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return { singlecategory, loading, error, fetchSingleCategory };
}

export default useSingleCategory