import React from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import useSingleCategory from './useSingleCategory';
import { useState } from 'react';

const UpdateCategoryModel = ({ show, onClose, category_code }) => {

    const { singlecategory, loading, error, fetchSingleCategory } = useSingleCategory(category_code);

    useEffect(() => {
        if (show) {
            if (category_code) {

                fetchSingleCategory();
            }
        }
    }, [show]);

    useEffect(() => {
        if (singlecategory) {
            setFormData({
                category_name: singlecategory.category_name || '',
                category_image: null,
                user_id: localStorage.getItem('user_id') || '',
                category_code: category_code || ''
            });
        }
    }, [singlecategory]);

    const [formData, setFormData] = useState({
        category_name: '',
        category_image: null,
        user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
        category_code: category_code
    });

    const handleChange = (event) => {
        const { name, value, type, files } = event.target;

        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Handle only the first file
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const token = localStorage.getItem('token');
        // Create a new FormData object
        const data = new FormData();
        data.append('category_name', formData.category_name || '');

        // Append image files only if they exist
        data.append('category_image', formData.category_image || '');

        data.append('user_id', formData.user_id || '');
        data.append('category_code', formData.category_code || '');
        console.log(data);

        try {
            const response = await fetch('https://backend.amjgems.com/public/api/categories-update', {
                method: 'POST',
                body: data, // FormData automatically sets the correct Content-Type header
                headers: {
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
            });
            // console.log(response);
            const result = await response.json();
            // Handle success (e.g., show a message or reset the form)
            form.reset();
            onClose();
            alert('Category Updated successfully');
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };


    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    Update Form for {category_code}
                </Modal.Header>
                <Modal.Body>
                    <div id='adminformdiv'>
                        <form id='categoryform' onSubmit={handleSubmit}>
                            <lable>Category Name</lable>
                            <input
                                className='admininput'
                                name='category_name'
                                type="text"
                                placeholder='Category Name'
                                value={formData.category_name}
                                required
                                onChange={handleChange}
                            />
                            <lable>Image for Category</lable>
                            <input
                                className='admininput'
                                type="file"
                                name='category_image'
                                required
                                onChange={handleChange}
                            />
                            <button className='adminbutton' type='submit'>Update</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UpdateCategoryModel