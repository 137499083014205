import React, { useState } from 'react'
import './adminlogin.scss'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie'; // Ensure this is installed
import {jwtDecode} from 'jwt-decode'; // Correct import

const Adminlogin = () => {

  const [email, setemail] = useState('');
  const [pass, setpass] = useState('');
  const navigate = useNavigate();

  const handleSubmitlogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://backend.amjgems.com/public/api/login', { // Replace with your API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: pass }),
      });
      const data = await response.json();
      console.log(data);

      // Store token and user ID in local storage
      if (data.token && data.user_id) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user_id', data.user_id);

        const decodedToken = jwtDecode(data.token);
        const expiryDate = new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds

        // Set cookie with user ID
        const cookies = new Cookies();
        cookies.set('user_id', data.user_id, { expires: expiryDate });

        navigate('/admin')
      } else {
        console.error('Token or UserId missing in response');
      }

    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  return (
    <div id='adminlogin'>
      <div id='adminformdiv'>
        <form id='addproductsform' onSubmit={handleSubmitlogin}>
          <label>Email</label>
          <input
            className='admininput'
            type="text"
            name='email'
            placeholder='E-mail'
            value={email}
            onChange={(e) => {
              setemail(e.target.value)
            }}
          />
          <label>Password</label>
          <input
            className='admininput'
            name='pass'
            type="password"
            placeholder='Password'
            value={pass}
            onChange={(e) => {
              setpass(e.target.value)
            }}
          />
          <button className='adminbutton' type='submit'>Login</button>
        </form>
      </div>
    </div>
  )
}

export default Adminlogin