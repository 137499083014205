// src/components/SignupModal.js
import React, { useState } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import useCarousel from '../Admin/useCarousel';
import loginimage from '../../images/new-login-signup.png';
import Swal from 'sweetalert2';

const SignupModal = ({ show, onClose }) => {
    const [index, setIndex] = useState(0);
    const { carousels } = useCarousel();

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // State variables for form fields
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://backend.amjgems.com/public/api/user/signup', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    password,
                    contact_no: contactNo
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                // Handle errors (e.g., validation errors) from the response
                setError(data);
                Swal.fire({
                    title: 'Failed to sign up: ' + (data.message || 'Unknown error') ,
                  })
                console.log(error);
                return;
            }

            setError(null);
            onClose();
            Swal.fire({
                title: 'Sign Up Successful! Verification link sent to your email.',
              })

        } catch (error) {
            setError('An error occurred while signing up.');
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainer">
                        <div className="left-side">
                            <h1>Sign Up</h1>
                            <div className="image-popupcontainer">
                                <Carousel activeIndex={index} onSelect={handleSelect}>
                                    {carousels.map((carousel, index) => (
                                        <Carousel.Item key={index}>
                                            <img
                                                className="d-block w-100"
                                                src={`https://backend.amjgems.com/public/${carousel.image_path}`}
                                                alt={`Slide ${index}`}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <input
                                    className='admininput'
                                    type="text"
                                    value={name}
                                    placeholder='Name'
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                    className='admininput'
                                    type="email"
                                    value={email}
                                    placeholder='Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    className='admininput'
                                    type="password"
                                    value={password}
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <input
                                    className='admininput'
                                    type="text"
                                    value={contactNo}
                                    placeholder='Contact Number'
                                    onChange={(e) => setContactNo(e.target.value)}
                                    required
                                />
                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="right-side">
                            <img src={loginimage} alt="Sign Up" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SignupModal;
