import React from 'react'
import './Landingpage.scss'
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import useCarousel from '../Admin/useCarousel';
import useCategories from '../Admin/useCategories';

const Landingpage = () => {

    const { categories, loding, error, fetchcategories } = useCategories();
    const [index, setIndex] = useState(0);
    const { carousels, loading, errorc, fetchCarousel } = useCarousel();

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <div id='landingpage'>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {carousels.map((carousel, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={`https://backend.amjgems.com/public/${carousel.image_path}`}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3>Amazing Gems & Jwellers</h3>
                            <p>Timeless Beauty with a Contemporary Twist</p>
                            <Link className="btn btn-primary" to={'#'}>Shop Now</Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>

            <div id='headinglpage' >
                <h2>Shop By Category</h2>
                <div id='desclpage'>Browse through your favorite categories. We've got them all!</div>
            </div>
            <div class="containerlpage">
                {categories.map((category) => (
                    <Link key={category.category_code} className='link' to={`/category/${category.category_code}`}>
                        <div class="cardlpage">
                        <img className='img-thumbnail' src={`https://backend.amjgems.com/public/${category.category_image}`} alt="" />
                            <h3>{category.category_name}</h3>
                            <button>Explore</button>
                        </div>
                    </Link>
                ))}

            </div>
        </div>
    )
}

export default Landingpage