import React, { useState } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
  // State to manage visibility of each footer column
  const [visibleColumns, setVisibleColumns] = useState({
    usefulLinks: false,
    information: false,
    contactUs: false,
    appDownload: false
  });

  // Function to toggle visibility of a specific column
  const toggleColumnVisibility = (column) => {
    setVisibleColumns(prevState => ({
      ...prevState,
      [column]: !prevState[column]
    }));
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className={`footer-column ${visibleColumns.usefulLinks ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('usefulLinks')}>
            Terms and Conditions
          </h3>
          <Link className="footer-link" id='aboutuslink' to={'/tac'}><span>Terms and Conditions</span></Link>
        </div>
        <div className={`footer-column ${visibleColumns.information ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('information')}>
            Information
          </h3>
          <a href="#" className="footer-link">Careers</a>
          <a href="#" className="footer-link">Blog</a>
          <a href="#" className="footer-link">Offers & Contest Details</a>
          <a href="#" className="footer-link">Help & FAQs</a>
          <a href="#" className="footer-link">About Amazing Gems & Jwellers</a>
        </div>
        <div className={`footer-column ${visibleColumns.contactUs ? 'active' : ''}`}>
          <h3 className="footer-title" onClick={() => toggleColumnVisibility('contactUs')}>
            Contact Us
          </h3>
          <a href="mailto:info@Amazing Gems & Jwellers.com" className="footer-link"><i className="fas fa-envelope"></i> Write to Us</a>
          <a href="tel:+18002660123" className="footer-link"><i className="fas fa-phone-alt"></i> 1800-266-0123</a>
          <a href="#" className="footer-link"><i className="fas fa-comments"></i> Chat with Us</a>
        </div>
      </div>
      <div className="copyright">
        &copy; 2024 Amazing Gems & Jwellers. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
