import React, { useState } from 'react'
import './Productshow.scss'
import useProductshow from './useProductshow'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import AddReviewModal from './AddReviewModal'
import LoginModal from '../Mainpage/LoginModal'
import Cookies from 'universal-cookie';
import CartModal from '../Mainpage/CartModal'

const cookies = new Cookies();

const Productshow = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []); // The empty dependency array ensures this runs only on mount

    const { product_code } = useParams(); // Correctly extract product_code from useParams

    // login modal when add to cart fails 
    const [loginShow, setLoginShow] = useState(false);

    const handleShowLogin = () => setLoginShow(true);

    //Single product fetching

    const { singleproduct, loading, error, fetchSingleProduct } = useProductshow(product_code);

    const [image, ssetimage] = useState(null);

    const setimage = (e) => {
        ssetimage(e.target.id);
    }

    // Update filters with product_code
    useEffect(() => {
        if (product_code) {

            fetchSingleProduct();
        }
    }, [product_code]);

    // Initialize state for quantity
    const [quantity, setQuantity] = useState(1);

    // Handler function to increment quantity
    const quantityHandler = () => {
        if (quantity < singleproduct.product_quantity) {
            setQuantity(quantity + 1);
        }
    };
    const quantityReducer = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    //Review Modal
    const [reviewShow, setreviewShow] = useState(false);

    const handleClose = () => {
        setreviewShow(false);
        setLoginShow(false);
        setShowCart(false);
    };
    const handleShowReview = () => setreviewShow(true);

    const [reviews, setReviews] = useState([]);
    const fetchreviews = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/public/api/reviews/${product_code}?limit=5`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            setReviews(data);
        } catch (error) {
            console.log(error.message);
        }
    };
    useEffect(() => {
        if (reviewShow == false) {
            fetchreviews();
        }

    }, [reviewShow])

    const timeSince = (date) => {
        const now = new Date();
        const elapsed = now - new Date(date);

        const seconds = Math.floor(elapsed / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);

        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    };

    //Add to cart handling
    const [viewCart, showCartButton] = useState(true);
    const [showCart, setShowCart] = useState(false);
    const handleShowCart = () => setShowCart(true);

    const addtocart = async (e) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        const id = e.target.id;
        try {
            const response = await fetch(`https://backend.amjgems.com/public/api/cart/add`, { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: product_code, quantity: parseInt(quantity) }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.full) {
                alert("Quantity reached");
            }
            showCartButton(false);
            if (id == 'buynow') {
                handleShowCart();
            }

        } catch (error) {
            localStorage.clear();
            cookies.remove('user_id');
            handleShowLogin();
            console.error('Error adding category:', error);
        }
    }


    return (
        <div>
            <div class="product-container">

                <div id='sideimages'>
                    <img className='img-thumbnail' id={singleproduct.product_img_main} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_main}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_1} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_1}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_2} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_2}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_3} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_3}`} alt="" />
                </div>
                <div class="product-image">
                    <img src={image ? `https://backend.amjgems.com/public/${image}` : `https://backend.amjgems.com/public/${singleproduct?.product_img_main}`} alt="MCQ Alexander McQueen Silver Leather Glitter Lace-Up Shoes" />
                </div>
                <div id='sideimageschange'>
                    <img className='img-thumbnail' id={singleproduct.product_img_main} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_main}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_1} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_1}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_2} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_2}`} alt="" />
                    <img className='img-thumbnail' id={singleproduct.product_img_3} onClick={setimage} src={`https://backend.amjgems.com/public/${singleproduct.product_img_3}`} alt="" />
                </div>
                <div class="product-details">
                    <div class="product-title">{singleproduct.product_name}</div>
                    <div class="product-price"> <span id='discount'>Offer Price</span> ₹{singleproduct.product_price * (1 - singleproduct.discount_percent / 100)}<span class="price-cut">₹{singleproduct.product_price}</span> </div>
                    <div class="discount-tag">Flat {singleproduct.discount_percent}% off</div>
                    <div class="product-description">{singleproduct.product_desc}</div>
                    <div id='cwdiv'>
                        <span >Carat : {singleproduct.product_carat}</span>
                        <span >Weight : {singleproduct.product_weight}</span>
                    </div>
                    <p class="discount" id='leftquantity'>Only {singleproduct.product_quantity} left!</p>

                    {singleproduct.product_quantity === 0 ?
                        (<p class="discount">Out of stock!</p>) :
                        <>
                            <div id='quantity'>
                                <span>Quantity</span>
                                <input
                                    type="number"
                                    value={quantity}
                                    readOnly
                                />
                                <button onClick={quantityHandler}>+</button>
                                <button onClick={quantityReducer}>-</button>
                            </div>

                            <div class="product-buttons">
                                {viewCart ?
                                    (<><button class="product-button" onClick={addtocart}>ADD TO BAG</button><button id='buynow' onClick={addtocart} class="product-button">BUY NOW</button></>) :
                                    <button class="product-button" onClick={handleShowCart}>View Cart</button>}
                            </div>
                        </>
                    }

                </div>
            </div>
            <div id='riview'>
                <button onClick={handleShowReview} className='reviewbut'>Write a Riview</button>

                {reviews.map((review) => (

                    <div class="review-container">
                        <span className="author-name">{review.user_name}</span>
                        <div className="review-content">
                            <div>{review.review}
                            </div>
                        </div>
                        <div class="author">
                            <div className="rating">
                                <span className={1 <= review.rating ? "star" : "nostar"}>★</span>
                                <span className={2 <= review.rating ? "star" : "nostar"}>★</span>
                                <span className={3 <= review.rating ? "star" : "nostar"}>★</span>
                                <span className={4 <= review.rating ? "star" : "nostar"}>★</span>
                                <span className={5 <= review.rating ? "star" : "nostar"}>★</span>
                            </div>
                            {/* <span className="author-verified">&#10004; User_name {review.user_id}</span> */}
                            <span className="author-date">{timeSince(review.created_at)}</span>
                        </div>
                    </div>

                ))}
                <Link to={`/reviewshow/${product_code}`} id='showmorebut'><button class="product-button">Show All Reviews</button></Link>
            </div>
            <AddReviewModal show={reviewShow} onClose={handleClose} product_id={product_code} />
            <LoginModal show={loginShow} onClose={handleClose} />
            <CartModal show={showCart} onClose={handleClose} />
        </div>
    )
}

export default Productshow