// src/components/LoginModal.js
import React, { useState ,useEffect } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import useCarousel from '../Admin/useCarousel';
import loginimage from '../../images/new-login-signup.png';
import Cookies from 'universal-cookie'; // Ensure this is installed
import {jwtDecode} from 'jwt-decode'; // Correct import
import Swal from 'sweetalert2';
import ForgetPassModel from './ForgetPassModel';


const LoginModal = ({ show, onClose }) => {

    const [index, setIndex] = useState(0);
    const { carousels } = useCarousel();

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [email, setemail] = useState('');
    const [pass, setpass] = useState('');

    const handleSubmitlogin = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://backend.amjgems.com/public/api/login', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email, password: pass }),
            });
            const data = await response.json();
            console.log(data);

            // Store token and user ID in local storage
            if (data.token && data.user_id) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);

                const decodedToken = jwtDecode(data.token);
                const expiryDate = new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds

                // Set cookie with user ID
                const cookies = new Cookies();
                cookies.set('user_id', data.user_id, { expires: expiryDate });

                onClose();
                Swal.fire({
                    title: 'Login Successful!',
                  })
            } else {
                console.error('Token or UserId missing in response');
                onClose();
                Swal.fire({
                    title: 'Login Failed!'+ (data.message || 'Unknown error'),
                  })
            }

        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const [ForgetPassShow, setForgetPassShow] = useState(false);

    const handleClose = () => {
        setForgetPassShow(false);
    };
    const handleForgetPassModel = () => {
        onClose();
        setForgetPassShow(true);
    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainer">
                        <div className="left-side">
                            <h1>Login</h1>
                            <div className="image-popupcontainer">
                                <Carousel activeIndex={index} onSelect={handleSelect}>
                                    {carousels.map((carousel, index) => (
                                        <Carousel.Item key={index}>
                                            <img
                                                className="d-block w-100"
                                                src={`https://backend.amjgems.com/public/${carousel.image_path}`}
                                                alt={`Slide ${index}`}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                            <form onSubmit={handleSubmitlogin}>
                                <input
                                    className='admininput'
                                    type="email"
                                    placeholder='E-mail'
                                    value={email}
                                    onChange={(e) => {
                                        setemail(e.target.value)
                                    }}
                                />
                                <input
                                    className='admininput'
                                    type="password"
                                    placeholder='Password'
                                    value={pass}
                                    onChange={(e) => {
                                        setpass(e.target.value)
                                    }}
                                />
                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Submit</button>
                                </div>
                            </form>
                            <button id='forgetpassbutton' onClick={handleForgetPassModel}>Forget Password !</button>
                        </div>
                        <div className="right-side">
                            <img src={loginimage} alt="Login" />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ForgetPassModel show={ForgetPassShow} onClose={handleClose} />
        </>
    );
};

export default LoginModal;
