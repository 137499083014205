// Addcategories.js
import React, { useState , useEffect } from 'react';
import useCategories from './useCategories.jsx'; // Import the custom hook
import UpdateCategoryModel from './UpdateCategoryModel.jsx';

const Addcategories = () => {

  const [categorycode, setCategorycode] = useState();
  const [updateShow, setupdateShow] = useState(false);

  const handleClose = () => {
    setupdateShow(false);
  };
  const handleShowupdate = (e) => {
    setCategorycode(e.target.id);
    setupdateShow(true);
  }

  const { categories, loading, error, fetchCategories } = useCategories();
  const [categoryName, setCategoryName] = useState('');
  const [categoryimage, setimage] = useState('');

  useEffect(() => {
    if(updateShow == false)
    {
      fetchCategories();
    }
  }, [updateShow]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id')?localStorage.getItem('user_id'):'';

    const data = new FormData();
    data.append('category_name', categoryName || '');
    data.append('category_image', categoryimage || '');
    data.append('user_id' , user_id || '');

    try {
       const response = await fetch('https://backend.amjgems.com/public/api/categories', { // Replace with your API endpoint
        method: 'POST',
        headers: {
          
          'Authorization': `Bearer ${token}` // Using Bearer token authentication
        },
        body: data,
      });

      fetchCategories(); // Refresh categories
      form.reset();
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };
  const deletecategory = async (event) => {
    const id = event.target.id ;
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id')?localStorage.getItem('user_id'):'';
    try {
      await fetch(`https://backend.amjgems.com/public/api/categories/${id}`, { // Replace with your API endpoint
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Using Bearer token authentication
        },
        body: JSON.stringify({user_id:user_id }),
      });
      fetchCategories(); // Refresh categories
    } catch (error) {
      console.error('Error adding category:', error);
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <div id='adminformdiv'>
        <form id='categoryform' onSubmit={handleSubmit}>
          <lable>Category Name</lable>
          <input
            className='admininput'
            type="text"
            placeholder='Category Name'
            value={categoryName}
            required
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <lable>Image for Category</lable>
           <input
            className='admininput'
            type="file"
            required
            onChange={(e) => setimage(e.target.files[0])}
          />
          <button className='adminbutton' type='submit'>Add Category</button>
        </form>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th>Categories</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.category_code}</td>
              <td>{category.category_name}</td>
              <td><img className='img-thumbnail' src={`https://backend.amjgems.com/public/${category.category_image}`} alt="" /></td>
              <td><button className='adminbutton' id={category.category_code} onClick={deletecategory}>Delete</button></td>
              <td><button className='adminbutton' id={category.category_code} onClick={handleShowupdate}>Update</button></td>
            </tr>
          ))}
        </tbody>
      </table>
      <UpdateCategoryModel show={updateShow} onClose={handleClose} category_code={categorycode} />
    </>
  );
};

export default Addcategories;
















// import React from 'react'

// const Addcategories = () => {
//   return (
//     <div id='adminformdiv'>
//       <form id='categoryform' action="">
//         <input className='admininput' type="text" placeholder='Category Name' />
//         <button className='adminbutton' type='submit'>Add Category</button>
//       </form>
//     </div>
//   )
// }

// export default Addcategories